import { Link } from "react-router-dom";
import moment from "moment";
import SmallProgress from "../../../shared/small-progress";
import './person.scss';
import CanAccessLink from "../../../shared/can-access-link";

const EmailPerson = ({ person, url, onSend, totalStatuses, canSend, readOnly, canAccess }) => {

    const getPersonProgress = (person) => {
        if (person.sending) {
            return 0;
        }

        var statusProgress = getStatusProgress(person.status);

        const progress = Math.round((statusProgress / totalStatuses) * 100);
        return progress > 100 ? 100 : progress;
    };

    const getStatusProgress = (status) => {
        switch (status) {
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
            case 4:
            case 5:
                return 3;
            case 6:
                return 4;
            case 19:
                return 5;
            case 20:
                return 6;
            case 30:
                return 7;
            default:
                return 0;
        }
    };

    const progress = getPersonProgress(person);

    const lastStatusChangedUtc = moment.utc(person.lastStatusChanged)
    var lastStatusChangedLocal = moment(lastStatusChangedUtc).local();
    var lastStatusChanged = lastStatusChangedLocal.format("Do MMMM YYYY, HH:mm:ss");

    return (
        <tr>
            <td>{person.notSignedRelease && <i className="material-icons call-sheet-not-signed-release-icon" title="Not signed release">edit_off</i>}<CanAccessLink to={url} canAccess={canAccess}>{person.fullName}</CanAccessLink></td>
            <td><a href={`mailto:${person.emailAddress}`}>{person.emailAddress}</a></td>
            <td className={`${(person.status >= 3 && person.status <= 5 && !person.sending) ? "error-text" : ""}`}>
                {person.status && <SmallProgress progress={progress} showPercentage={false} failed={person.status >= 3 && person.status <= 5 && !person.sending} />}
                <span title={lastStatusChanged}>{person.sending ? "Sending" : person.statusDescription}</span>
            </td>
            <td className="has-text-centered">{person.emailAddress && canSend && !readOnly &&
                <button className={`button is-small ${person.sending ? "is-loading" : ""}`} onClick={() => onSend(person.id, person.status >= 1)} disabled={person.disabled}>{person.status < 1 ? "Send" : "Resend"}</button>
            }</td>
        </tr>
    )
}

export default EmailPerson;
