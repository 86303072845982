import i18n from 'i18next';
import auth from "../../shared/auth";
import { showNotification } from '../../shared/notification/browser-notification';
import { FETCH_UNIONS, LOG_ERROR } from "./actions";

interface PageSection {
  path: string,
  name: string,
  hasOnboarding: boolean
}

interface DefaultState {
  aspectRatios: [];
  filmTypes: [];
  authenticated: boolean;
  user: {};
  activeOnboarding: null | string;
  activeOnboardingStep: number;
  onboardingComplete: [] | null;
  pageSections: PageSection[];
  activeSection: string | null;
  generalUserMessages: any[];
  currencies: any[],
  userCurrencyId: any,
  userLanguageId: any,
  languageHasGender: boolean,
  loading: boolean,
  loadingOnBoarding: boolean,
  apiVersion: string | null,
  equipmentCategories: any[]
}

const pageSections = [
  {
    path: '/films',
    name: 'Films',
    hasOnboarding: true
  },
  {
    path: '/script/upload',
    name: 'Script Upload',
    hasOnboarding: true
  },
  {
    path: '/films/:id/overview',
    name: 'Overview',
    hasOnboarding: false
  },
  {
    path: '/films/:id/edit',
    name: 'Film',
    hasOnboarding: true
  },
  {
    path: '/films/:id/breakdown/overview',
    name: 'Breakdown',
    hasOnboarding: false
  },
  {
    path: '/films/:id/script',
    name: 'Script',
    hasOnboarding: true
  },
  {
    path: '/films/:id/collaborators',
    name: 'Collaborators',
    hasOnboarding: true
  },
  {
    path: '/films/:id/collaborators/overview',
    name: 'Collaborators',
    hasOnboarding: true
  },
  {
    path: '/films/:id/collaborators/collaborator-roles/:collaboratorRoleId/edit',
    name: 'Collaborator Role',
    hasOnboarding: true
  },
  {
    path: '/films/:id/collaborators/collaborator-roles/add',
    name: 'Collaborator Role',
    hasOnboarding: true
  },
  {
    path: '/films/:id/tasks',
    name: 'Tasks',
    hasOnboarding: false
  },
  {
    path: '/films/:id/characters/overview',
    name: 'Characters Overview',
    hasOnboarding: true
  },
  {
    path: '/films/:id/characters/add',
    name: 'Add Character',
    hasOnboarding: false
  },
  {
    path: '/films/:id/characters/:characterId/edit',
    name: 'Edit Character',
    hasOnboarding: false
  },
  {
    path: '/films/:id/background-characters/overview',
    name: 'Background Characters',
    hasOnboarding: true
  },
  {
    path: '/films/:id/background-characters/add',
    name: 'Add Background Character',
    hasOnboarding: false
  },
  {
    path: '/films/:id/background-characters/:characterId/edit',
    name: 'Edit Background Character',
    hasOnboarding: false
  },
  {
    path: '/films/:id/scene-settings/overview',
    name: 'Scene Settings',
    hasOnboarding: true
  },
  {
    path: '/films/:id/scene-settings/:sceneSettingId/edit',
    name: 'Edit Scene Setting',
    hasOnboarding: false
  },
  {
    path: '/films/:id/scene-settings/add',
    name: 'Add Scene Setting',
    hasOnboarding: false
  },
  {
    path: '/films/:id/scenes',
    name: 'Scenes',
    hasOnboarding: false
  },
  {
    path: '/films/:id/scenes/:sceneId/edit',
    name: 'Edit Scene',
    hasOnboarding: true
  },
  {
    path: '/films/:id/scenes/add',
    name: 'Add Scene',
    hasOnboarding: true
  },
  {
    path: '/films/:id/scenes/script-days',
    name: 'Script Days',
    hasOnboarding: true
  },
  {
    path: '/films/:id/scenes/order',
    name: 'Scene Order',
    hasOnboarding: true
  },
  {
    path: '/films/:id/actors/order',
    name: 'Actor Order',
    hasOnboarding: true
  },
  {
    path: '/films/:id/actors/upload',
    name: 'Actor Upload',
    hasOnboarding: true
  },
  {
    path: '/films/:id/actors',
    name: 'Actors',
    hasOnboarding: false
  },
  {
    path: '/films/:id/actors/overview',
    name: 'Actors Overview',
    hasOnboarding: true
  },
  {
    path: '/films/:id/actors/add',
    name: 'Add Actor',
    hasOnboarding: true
  },
  {
    path: '/films/:id/actors/:actorId/edit',
    name: 'Edit Actor',
    hasOnboarding: true
  },
  {
    path: '/films/:id/background-actors/overview',
    name: 'Background Actors Overview',
    hasOnboarding: true
  },
  {
    path: '/films/:id/background-actors',
    name: 'Background Actors',
    hasOnboarding: false
  },
  {
    path: '/films/:id/background-actors/add',
    name: 'Add Background Actor',
    hasOnboarding: false
  },
  {
    path: '/films/:id/background-actors/:actorId/edit',
    name: 'Edit Background Actor',
    hasOnboarding: false
  },
  {
    path: '/films/:id/locations',
    name: 'Shooting Locations',
    hasOnboarding: false
  },
  {
    path: '/films/:id/locations/overview',
    name: 'Shooting Locations Overview',
    hasOnboarding: true
  },
  {
    path: '/films/:id/locations/add',
    name: 'Add Shooting Location',
    hasOnboarding: true
  },
  {
    path: '/films/:id/locations/:locationId/edit',
    name: 'Edit Shooting Location',
    hasOnboarding: true
  },
  {
    path: '/films/:id/costumes',
    name: 'Costumes',
    hasOnboarding: false
  },
  {
    path: '/films/:id/costumes/add',
    name: 'Add Costume',
    hasOnboarding: false
  },
  {
    path: '/films/:id/costumes/:costumeId/edit',
    name: 'Edit Costume',
    hasOnboarding: false
  },
  {
    path: '/films/:id/costumes/:costumeId/scenes',
    name: 'Costume Scenes',
    hasOnboarding: true
  },
  {
    path: '/films/:id/makeup',
    name: 'Makeup',
    hasOnboarding: false
  },
  {
    path: '/films/:id/makeup/add',
    name: 'Add Makeup',
    hasOnboarding: false
  },
  {
    path: '/films/:id/makeup/:makeupId/edit',
    name: 'Edit Makeup',
    hasOnboarding: false
  },
  {
    path: '/films/:id/makeup/:makeupId/scenes',
    name: 'Makeup Scenes',
    hasOnboarding: true
  },
  {
    path: '/films/:id/props',
    name: 'Props',
    hasOnboarding: false
  },
  {
    path: '/films/:id/props/add',
    name: 'Add Prop',
    hasOnboarding: false
  },
  {
    path: '/films/:id/props/:propId/edit',
    name: 'Edit Prop',
    hasOnboarding: false
  },
  {
    path: '/films/:id/props/:propId/scenes',
    name: 'Prop Scenes',
    hasOnboarding: true
  },
  {
    path: '/films/:id/set-dressings',
    name: 'Set Dressings',
    hasOnboarding: false
  },
  {
    path: '/films/:id/set-dressings/add',
    name: 'Add Set Dressing',
    hasOnboarding: false
  },
  {
    path: '/films/:id/set-dressings/:setDressingId/edit',
    name: 'Edit Set Dressing',
    hasOnboarding: false
  },
  {
    path: '/films/:id/set-dressings/:setDressingId/scenes',
    name: 'Set Dressing Scenes',
    hasOnboarding: true
  },
  {
    path: '/films/:id/equipment-category',
    name: 'Equipment Categories',
    hasOnboarding: false
  },
  {
    path: '/films/:id/equipment-category/:categoryId/equipment/:equipmentId',
    name: 'Equipment',
    hasOnboarding: false
  },
  {
    path: '/films/:id/equipment-category/:categoryId/equipment/:equipmentId/scenes',
    name: 'Equipment Scenes',
    hasOnboarding: true
  },
  {
    path: '/films/:id/departments',
    name: 'Crew',
    hasOnboarding: false
  },
  {
    path: '/films/:id/crew/upload',
    name: 'Crew Upload',
    hasOnboarding: true
  },
  {
    path: '/films/:id/availability',
    name: 'Scheduling',
    hasOnboarding: false
  },
  {
    path: '/films/:id/budget',
    name: 'Budget',
    hasOnboarding: true
  },
  {
    path: '/films/:id/shooting-days',
    name: 'Shooting Days',
    hasOnboarding: false
  },
  {
    path: '/films/:id/shooting-days/shooting-order',
    name: 'Shooting Order',
    hasOnboarding: true
  },
  {
    path: '/films/:id/shooting-days/:shootigDayId/shot-list-schedule',
    name: 'Shot List Schedule',
    hasOnboarding: true
  },
  {
    path: '/films/:id/shooting-days/settings',
    name: 'Shooting Day Settings',
    hasOnboarding: true
  },
  {
    path: '/films/:id/shooting-days/calendar',
    name: 'Shooting Day Calendar',
    hasOnboarding: true
  },
  {
    path: '/films/:id/shooting-days/:shootigDayId/risk-assessments',
    name: 'Risk Assessments',
    hasOnboarding: true
  },
  {
    path: '/films/:id/shooting-days/:shootigDayId/call-sheet',
    name: 'Call Sheet',
    hasOnboarding: true
  },
  {
    path: '/user/settings',
    name: 'User Settings',
    hasOnboarding: true
  },
  {
    path: '/account',
    name: 'Account',
    hasOnboarding: false
  },
  {
    path: '/user',
    name: 'User Details',
    hasOnboarding: false
  },
  {
    path: '/films/:id/shooting-days/messages',
    name: 'Messages',
    hasOnboarding: true
  },
  {
    path: '/films/:id/scheduling/potential-dates',
    name: 'Potential Dates',
    hasOnboarding: true
  }
].sort((a, b) => {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
});

const defaultState: DefaultState = {
  aspectRatios: [],
  filmTypes: [],
  authenticated: false,
  user: {},
  activeOnboarding: null,
  activeOnboardingStep: 0,
  onboardingComplete: null,
  pageSections: pageSections,
  activeSection: null,
  generalUserMessages: [],
  currencies: [],
  userCurrencyId: null,
  userLanguageId: null,
  languageHasGender: false,
  loading: false,
  loadingOnBoarding: false,
  apiVersion: null,
  equipmentCategories: []
};

const reducer = (state = defaultState, action: any = {}) => {
  if (action.type.endsWith("_FULFILLED")) {
    let sessionToken = action.payload.data.sessionToken;
    if (sessionToken) {
      //console.log(action.payload.data.sessionToken);
      (<any>window).sessionToken = sessionToken;
    }
  }

  switch (action.type) {
    case "FETCH_API_VERSION_FULFILLED": {
      return {
        ...state,
        apiVersion: action.payload.data,
        loading: false
      };
    }

    case "FETCH_ALL_ENUMS_FULFILLED": {
      const enums = action.payload.data;
      return {
        ...state,
        genderEnum: enums.gender,
        dayNightEnum: enums.dayNight,
        interiorExteriorEnum: enums.interiorExterior,
        filmTypeEnum: enums.filmType,
        filmStatusEnum: enums.filmStatus,
        cameraMovementEnum: enums.cameraMovement,
        shotSizeEnum: enums.shotSize,
        dietaryRequirementEnum: enums.dietaryRequirement,
        acquisitionMethod: enums.acquisitionMethod,
        weightUnitEnum: enums.weightUnit,
        heightUnitEnum: enums.heightUnit,
        distanceUnitEnum: enums.distanceUnit,
        temperatureUnitEnum: enums.temperatureUnit,
        windSpeedUnitEnum: enums.windSpeedUnit,
        userAccessEnum: enums.userAccess,
        callSheetSectionEnum: enums.callSheetSection,
        weatherSectionEnum: enums.weatherSection,
        changeLogChangeTypeEnum: enums.changeLogChangeType,
        loading: false
      };
    }

    case "FETCH_ALL_ENUMS_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    case "FETCH_ALL_LOOKUPS_FULFILLED": {
      const lookups = action.payload.data;
      return {
        ...state,
        aspectRatios: lookups.aspectRatios,
        genreTypes: lookups.genres,
        skinColors: lookups.skinColors,
        focalLengths: lookups.focalLengths,
        frameRates: lookups.standardFrameRates,
        slowMotionFrameRates: lookups.allFrameRates,
        resolutions: lookups.resolutions,
        ethnicityTypes: lookups.ethnicities,
        pricingPlans: lookups.pricingPlans,
        currencies: lookups.currencies,
        languages: lookups.languages,
        equipmentCategories: lookups.equipmentCategories,
        drinks: lookups.drinks,
        milkTypes: lookups.milkTypes,
        loading: false
      };
    }

    case "FETCH_ALL_LOOKUPS_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    case FETCH_UNIONS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_UNIONS + "_FULFILLED": {
      return {
        ...state,
        actorUnions: action.payload.data.actorUnions,
        crewUnions: action.payload.data.crewUnions,
        loading: false
      }
    }

    case FETCH_UNIONS + "_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    case "LOGIN_FULFILLED": {
      auth.setToken(action.payload.data);
      i18n.changeLanguage(action.payload.data.languageCode);
      return {
        ...state,
        user: action.payload.data,
        generalUserMessages: action.payload.data.userMessages,
        userCurrencyId: action.payload.data.currencyId,
        userLanguageId: action.payload.data.languageId,
        languageHasGender: action.payload.data.languageHasGender,
        authenticated: true
      };
    }

    case "USER_LOADED": {
      const authenticated = auth.isAuthenticated();
      i18n.changeLanguage(action.payload?.languageCode);
      return {
        ...state,
        user: action.payload,
        authenticated: authenticated
      };
    }

    case "ADD_USER_MESSAGE": {
      let userMessage = action.meta.userMessage;

      if (userMessage) {
        if (userMessage.filmId != null) {
          return state;
        }

        const generalUserMessages = Array.from(state.generalUserMessages);
        let existingMessage = userMessage.allowDuplicates ? null : generalUserMessages?.find(m => m.message === userMessage.message && m.entityId === userMessage.entityId && m.entityType == userMessage.entityType);
        if (!existingMessage) {
          generalUserMessages.push(userMessage);
          showNotification(userMessage.entityName, userMessage.message, userMessage.link);
        } else {
          existingMessage.entityName = userMessage.entityName;
          existingMessage.severity = userMessage.severity;
          existingMessage.added = userMessage.added;
        }

        return {
          ...state,
          generalUserMessages
        };
      }

      return state;
    }

    case "REMOVE_USER_MESSAGE": {
      let removeUserMessage = action.meta.userMessage;
      if (removeUserMessage) {
        if (removeUserMessage.filmId != null) {
          return state;
        }

        const generalUserMessages = Array.from(state.generalUserMessages);
        let existingMessageIndex = generalUserMessages.findIndex(m => m.filmId === removeUserMessage.filmId && m.message === removeUserMessage.message && m.entityId === removeUserMessage.entityId && m.entityType == removeUserMessage.entityType);
        if (existingMessageIndex > -1) {
          generalUserMessages.splice(existingMessageIndex, 1);
        }

        return {
          ...state,
          generalUserMessages
        };
      }

      return state;
    }

    case "DISMISS_USER_MESSAGE": {
      let removeUserMessageId = action.meta.userMessageId;
      if (removeUserMessageId) {
        const generalUserMessages = Array.from(state.generalUserMessages);
        let existingMessageIndex = generalUserMessages.findIndex(m => m.id === removeUserMessageId);
        if (existingMessageIndex > -1) {
          generalUserMessages.splice(existingMessageIndex, 1);
        }

        return {
          ...state,
          generalUserMessages
        };
      }

      return state;
    }

    case "UPDATE_USER_FULFILLED": {
      const name = action.payload.data.name;
      const firstName = action.meta.user.firstName;
      const lastName = action.meta.user.lastName;
      const profileBackgroundColor = action.meta.user.profileBackgroundColor;
      const user = { ...state.user, name, firstName, lastName, profileBackgroundColor };
      auth.setToken(user);
      return {
        ...state,
        user: user
      };
    }

    case "LOGOUT": {
      return {
        ...state,
        authenticated: false
      };
    }
    /*
        case "AUTHENTICATED": {            
          return {
            ...state,      
            authenticated: true
          };
        }
    */

    case "DISMISS_MESSAGE_FULFILLED": {
      return state;
    }

    case "DISMISS_ALL_USER_MESSAGES_FULFILLED": {
      return state;
    }

    case "SET_ACTIVE_ONBOARDING": {
      return {
        ...state,
        activeOnboarding: action.payload
      }
    }

    case "SET_ACTIVE_ONBOARDING_STEP": {
      return {
        ...state,
        activeOnboardingStep: action.payload
      }
    }

    case "SET_ONBOARDING_COMPLETE": {
      return {
        ...state,
        onboardingComplete: action.payload
      }
    }

    case "UPDATE_ONBOARDING_COMPLETE": {
      return {
        ...state,
        onboardingComplete: state?.onboardingComplete ? [...state.onboardingComplete, action.payload] : [action.payload]
      }
    }

    case "FETCH_USER_ONBOARDING_PENDING": {
      return {
        ...state,
        loadingOnBoarding: true
      };
    }

    case "FETCH_USER_ONBOARDING_FULFILLED": {
      return {
        ...state,
        onboardingComplete: action.payload.data.completedUserOnboarding,
        generalUserMessages: action.payload.data.userMessages,
        userCurrencyId: action.payload.data.currencyId,
        userLanguageId: action.payload.data.languageId,
        loadingOnBoarding: false
      };
    }

    case "SET_ACTIVE_SECTION": {
      return {
        ...state,
        activeSection: action.payload
      };
    }

    case LOG_ERROR + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case LOG_ERROR + "_FULFILLED": {
      return {
        ...state,
        loading: false
      }
    }

    case LOG_ERROR + "_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
