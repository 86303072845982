import { Link } from "react-router-dom";
import auth from "../../../shared/auth";
import SmallLoader from "../../../shared/small-loader/small-loader";
import { OnboardingStep2ClassName } from "./ducks/reducer";

function OverviewActor(props) {
    const {
        actor,
        index,
        readOnly,
        filmId,
        updateActorSignedRelease
    } = props;

    return (
        <tr>
            <td>
                <div className="actor-overview-row">
                    <Link className="actor-overview-row" to={`${actor.id}/edit`}><span>{actor.number}.&nbsp;</span><span>{actor.name}</span></Link>
                </div>
                {actor.mainImageUrl && <Link to={`${actor.id}/edit`}><img width={100} className="actor-overview-image" src={`${actor.mainImageUrl}?t=${auth.getImageToken()}`}></img></Link>}
            </td>
            <td>
                {(actor.characters || []).map((character, index) => <><Link to={`/films/${filmId}/characters/${character.id}/edit`}>{character.name}</Link>{index < actor.characters.length - 1 ? ", " : ""}</>)}
            </td>
            <td className={`actor-overview-signed-column ${index === 0 ? OnboardingStep2ClassName : ""}`}>
                {actor.updating && <SmallLoader className="actor-overview-loader" />}
                {!actor.updating && actor.signedRelease && <i className={`material-icons call-sheet-not-signed-release-icon ${readOnly ? "" : "is-clickable"}`} title="Signed release" onClick={() => updateActorSignedRelease(actor, false)}>edit</i>}
                {!actor.updating && !actor.signedRelease && <i className={`material-icons call-sheet-not-signed-release-icon ${readOnly ? "" : "is-clickable"}`} title="Not signed release" onClick={() => updateActorSignedRelease(actor, true)}>edit_off</i>}
            </td>
        </tr >
    );
}

export default OverviewActor;
