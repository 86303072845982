import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import EventDetails from "./event-details";
import "./shooting-days-calendar.scss";
import Checkbox from "../../../shared/filmanize-checkbox";
import { isAbsolute } from "path";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from "./ducks/reducer";
import confirm from "../../../shared/modal/confirm";
import DaysOfWeek from "./days-of-week";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

const Calendar = ({
  days,
  potentialDates,
  updateEvent,
  updateDate,
  changeStartDate,
  onUpdateShootingCalendar,
  daysOfWeek,
  changeDayOfWeek,
  readOnly,
  defaultAllowChangingDayOrder,
  filmId
}) => {

  const cal = useRef<any>();
  const forceUpdate = useForceUpdate();

  const [firstLoad, setFirstLoad] = useState<Boolean>(true);
  const [allowChangingDayOrder, setAllowChangingDayOrder] = useState<Boolean>(false);

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  useEffect(() => {
    setAllowChangingDayOrder(defaultAllowChangingDayOrder);
  }, [defaultAllowChangingDayOrder]);

  //const today = new Date(new Date().toDateString());
  const today = new Date();

  // var now = new Date();
  // var today = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  //today.setHours(0, 0, 0, 0);
  today.setUTCHours(0, 0, 0, 0);
  //today.setMinutes(0);
  //today.setSeconds(0);
  //today.setMilliseconds(0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  useEffect(() => {
    if (days?.length > 0 && firstLoad) {
      if (days[days.length - 1].start < today) {
        cal.current.getApi().gotoDate(days[days.length - 1].start);
      }

      if (days[0].start > today) {
        cal.current.getApi().gotoDate(days[0].start);
      }
      setFirstLoad(false);
    }
  }, [days]);

  const handleEventChange = (changeInfo) => {
    updateEvent(changeInfo.event.toPlainObject(), allowChangingDayOrder);
  };

  const handleChangeStartDate = (dateInfo) => {
    changeStartDate(dateInfo.date);
  };

  const onChangeDayOfWeek = (dayOfWeek, checked) => {
    changeDayOfWeek(dayOfWeek, checked);
    forceUpdate();
  };


  const eventAllow = (dropInfo, draggedEvent) => {
    if (readOnly) {
      return false;
    }

    if (checkDateIsBeforeToday(dropInfo.start)) {
      return false;
    }

    if (allowChangingDayOrder) {
      return true;
    }

    // if dropInfo.start is the same as a day that is not editable, return false
    // and not the day we are dragging
    //dropInfo.start.toISOString() == days[1].start.toISOString() 
    var dayOnThisDay = days.find((d) => !d.isPotentialDate && d.start?.toISOString() === dropInfo.start?.toISOString() && d.id != draggedEvent.id);

    if (dayOnThisDay) {
      if (!dayOnThisDay.editable) {
        return false;
      }
    }

    const dayIndex = days.findIndex((d) => d.id === draggedEvent.id);
    if (dayIndex === 0) {
      return true;
    }

    const previosuDay = days[dayIndex - 1];

    return dropInfo.start > previosuDay.start;
  };


  const eventMouseEnter = (info) => {
    const { event, jsEvent } = info;
    const day = days.find((d) => d.id === event.id);
    if (day.isPotentialDate) {
      return;
    }
    const { clientX, clientY } = jsEvent;
    const style = {
      zIndex: 10,
      background: "#040f21",
      padding: "0.2rem",
      borderRedius: "5px",
      position: "absolute",
      left: clientX,
      top: clientY
    };
    // setDayDetails({ day, style });
  };

  const eventMouseClick = (info) => {

    const { event, jsEvent } = info;
    const day = days.find((d) => d.id === event.id);
    if (day.isPotentialDate) {
      return;
    }

    //const { clientX, clientY } = jsEvent;
    const style = {
      // zIndex: 10,
      // background: "#040f21",
      // padding: "0.2rem",
      // borderRedius: "5px",
      // position: "absolute",
      // left: clientX,
      // top: clientY
    };
    setDayDetails({ day, style });
  };

  const eventMouseLeave = (mouseEnterInfo) => {
    const event = mouseEnterInfo.event;
    const jsEvent = mouseEnterInfo.jsEvent;
    //setDayDetails({});
  };


  const onCloseEventDetails = () => {
    setDayDetails({});
  };

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  let daysOfWeekArray: any[] = [];
  for (var day in daysOfWeek) {
    daysOfWeekArray.push({
      dayOfWeek: day,
      selected: daysOfWeek[day].selected,
      name: daysOfWeek[day].name
    });
  }

  const sunday = daysOfWeekArray.splice(0, 1);
  daysOfWeekArray.push(sunday[0]);

  const hiddenDays = daysOfWeekArray.filter(d => !d.selected).map(d => +d.dayOfWeek);
  const workingDays = daysOfWeekArray.filter(d => d.selected).map(d => +d.dayOfWeek);

  const [dayDetails, setDayDetails] = useState({});

  const eventDetailsStyle = {
    position: "absolute"
  };

  const dayRender = ({ date, el }) => {
    if (checkDateIsBeforeToday(date)) {
      el.style.backgroundColor = '#040f21'
    }
    // else if (checkIfPotentialDay(date)) {
    //   el.style.backgroundColor = '#040f21'
    // }
  };

  const checkDateIsBeforeToday = (date) => {
    //if (new Date(date.toDateString()) < today) { //|| !daysOfWeek[date.getDay()].selected
    date.setUTCHours(0, 0, 0, 0);
    return date < today;
  };

  const checkIfPotentialDay = (date) => {
    return potentialDates.findIndex(d => d.start.toISOString() === date.toISOString()) > -1;
  };

  const onUpdateDate = (dayId, newDate) => {

    var date = new Date(newDate);
    // check date is not in past
    if (checkDateIsBeforeToday(date)) {
      confirm(null, null, "Date can't be less than today", "Error");
      return false;
    }

    //or on day of non moveable one
    var dayOnThisDay = days.find((d) => !d.isPotentialDate && d.start.toISOString() === date.toISOString() && d.id != dayId);
    if (dayOnThisDay) {
      if (!dayOnThisDay.editable) {
        // there is a non editable day on this date
        confirm(null, null, "There is already a non moveable day on this date", "Error");
        return false;
      }
    }

    // and date is greater than day number less than this one
    var day = days.find((d) => d.id === dayId);
    const previosuDaysIndex = days.findIndex(d => !d.isPotentialDate && d.number < day.number && d.start >= date);
    if (previosuDaysIndex >= 0 && !allowChangingDayOrder) {
      confirm(null, null, "You cannot move this day before a day with a lower day number.", "Error");
      return false;
    }

    // check date is not on day of week not allowed
    if (hiddenDays.some(d => d === date.getDay())) {
      confirm(null, null, "The date is a non working day", "Error");
      return false;
    }

    //move calendar to this date?
    cal.current.getApi().gotoDate(date);

    updateDate(dayId, date, allowChangingDayOrder);
  };

  const selectAllow = (selectInfo) => {
    return false;
  };

  return (
    <>
      <p className="menu-label">Shooting Days Calendar</p>
      <div className={`calendar-working-days ${OnboardingStep1ClassName}`}>
        <p className="mr-1">Working Days:</p>
        <DaysOfWeek daysOfWeek={daysOfWeekArray} readOnly={readOnly} onChange={onChangeDayOfWeek} />
      </div>
      <div className="mb-1">
        <FilmanizeCheckbox label="Allow changing day order" checked={allowChangingDayOrder} onChange={setAllowChangingDayOrder} />
      </div>

      <div className={OnboardingStep2ClassName}>
        <FullCalendar
          ref={cal}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          eventColor="#947eed"
          events={days}
          editable={!readOnly}
          selectable={!readOnly}
          eventOverlap={true}
          firstDay={1}
          selectMirror={true}
          eventConstraint={{
            daysOfWeek: daysOfWeekArray
              .filter((d) => d.selected)
              .map((d) => d.dayOfWeek)
          }}
          // validRange={{
          //   start: today
          // }}
          //hiddenDays={hiddenDays}
          initialDate={days[0]?.start}
          contentHeight={"auto"}
          eventChange={handleEventChange}
          dateClick={handleChangeStartDate}
          eventAllow={eventAllow}
          selectAllow={selectAllow}
          //selectAllow={() => false}
          eventClick={eventMouseClick}
          eventMouseEnter={eventMouseEnter}
          eventMouseLeave={eventMouseLeave}
          eventStartEditable={!readOnly}
          showNonCurrentDates={true}
          dayCellDidMount={dayRender}
          businessHours={{
            daysOfWeek: workingDays,
            startTime: '08:00',
            endTime: '18:00'
          }}
          dayHeaderClassNames="calendar-header"
          timeZone="UTC"
        />
        {potentialDates?.length > 0 &&
          <div className="shooting-days-potential-day-container">
            <div className="shooting-days-potential-day-background"></div>
            Potential Date
          </div>}

      </div>
      <EventDetails {...dayDetails} close={onCloseEventDetails} updateDate={onUpdateDate} readOnly={readOnly} filmId={filmId} />
      {!readOnly && <div className="buttons mt-2">
        <button
          type="submit"
          className="button"
          onClick={() => onUpdateShootingCalendar(allowChangingDayOrder)}>
          Save
        </button>
      </div>}
    </>
  );
};

export default Calendar;
