import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";
import EmailPerson from "./email-person";
import './email-recipients.scss'
import { OnboardingStep7ClassName } from "./ducks/reducer";
import WarningText from "../../../shared/warning-text";

function EmailRecipients(props) {
  const { filmId,
    onSendPerson,
    includeClicked,
    clickedName,
    includeSubmitted,
    submittedName,
    totalStatuses,
    canSend,
    shootingDaySettings,
    actorType,
    backgroundActorType,
    crewMemberType,
    readOnly,
    canAccessActors,
    canAccessBackgroundActors,
    canAccessCrew } = props;

  const allPeople = shootingDaySettings?.actors?.concat(shootingDaySettings?.backgroundActors).concat(shootingDaySettings?.crewMembers);
  const totalPeople = allPeople?.length ?? 1;
  const sentCount = allPeople?.filter(person => person.status >= 1).length ?? 0;
  const setPercentage = Math.round((sentCount / totalPeople) * 1000) / 10;
  const deliveredCount = allPeople?.filter(person => person.status >= 6).length ?? 0;
  const deliveredPercentage = Math.round((deliveredCount / totalPeople) * 1000) / 10;
  const openedCount = allPeople?.filter(person => person.status >= 19).length ?? 0;
  const openedPercentage = Math.round((openedCount / totalPeople) * 1000) / 10;
  const clickedCount = allPeople?.filter(person => person.status >= 20).length ?? 0;
  const clickeddPercentage = Math.round((clickedCount / totalPeople) * 1000) / 10;
  const submittedCount = allPeople?.filter(person => person.status >= 30).length ?? 0;
  const submittedPercentage = Math.round((submittedCount / totalPeople) * 1000) / 10;

  return (
    <div>
      <div className={OnboardingStep7ClassName}>
        <label className="label">Email Recipients</label>
        {shootingDaySettings?.hasActorsWithoutSignedRelease && <WarningText><i className="material-icons call-sheet-not-signed-release-icon" title="Not signed release">edit_off</i> Some actors have not signed release.</WarningText>}
        {shootingDaySettings?.hasPeopleWithoutEmailAddress && <WarningText>Some people have no email addresses.</WarningText>}
        {sentCount > 0 && <div className="email-recipients-progress-container">
          <div className="small-circular-progress">
            <FilmalityCircularProgress
              className="small-box"
              percentage={setPercentage}
              title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sent&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            />
          </div>

          <div className="small-circular-progress">
            <FilmalityCircularProgress
              className="small-box"
              percentage={deliveredPercentage}
              title="Delivered"
            />
          </div>

          <div className="small-circular-progress">
            <FilmalityCircularProgress
              className="small-box"
              percentage={openedPercentage}
              title="Opened"
            />
          </div>
          {includeClicked && <div className="small-circular-progress">
            <FilmalityCircularProgress
              className="small-box"
              percentage={clickeddPercentage}
              title={clickedName}
            />
          </div>}
          {includeSubmitted && <div className="small-circular-progress">
            <FilmalityCircularProgress
              className="small-box"
              percentage={submittedPercentage}
              title={submittedName}
            />
          </div>}
        </div>}
      </div>
      <table className="table mt-1">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(shootingDaySettings?.actors?.length > 0) && <tr>
            <td colSpan={4}><div className="has-text-weight-bold">Actors</div></td>
          </tr>}
          {(shootingDaySettings?.actors || []).map((actor: any) => {
            const url = `/films/${filmId}/actors/${actor.id}/edit`;
            return (<EmailPerson key={actor.id} person={actor} url={url} onSend={(personId, resend) => onSendPerson(personId, actorType, actor.fullName, resend)} totalStatuses={totalStatuses} canSend={canSend} readOnly={readOnly} canAccess={canAccessActors} />)
          })}
          {(shootingDaySettings?.backgroundActors?.length > 0) && <tr>
            <td colSpan={4}><div className="has-text-weight-bold">Background Actors</div></td>
          </tr>}
          {(shootingDaySettings?.backgroundActors || []).map((backgroundActor: any) => {
            const url = `/films/${filmId}/background-actors/${backgroundActor.id}/edit`;
            return (<EmailPerson key={backgroundActor.id}
              person={backgroundActor}
              url={url}
              onSend={(personId, resend) => onSendPerson(personId, backgroundActorType, backgroundActor.fullName, resend)}
              totalStatuses={totalStatuses}
              canSend={canSend}
              readOnly={readOnly}
              canAccess={canAccessBackgroundActors} />)
          })}
          {(shootingDaySettings?.crewMembers?.length > 0) && <tr>
            <td colSpan={4}><div className="has-text-weight-bold">Crew</div></td>
          </tr>}
          {(shootingDaySettings?.crewMembers || []).map((crewMember: any) => {
            const url = `/films/${filmId}/departments/${crewMember.crewDepartmentId}/roles/${crewMember.crewRoleId}/members/${crewMember.id}/edit`;
            return (<EmailPerson key={crewMember.id}
              person={crewMember}
              url={url}
              onSend={(personId, resend) => onSendPerson(personId, crewMemberType, crewMember.fullName, resend)}
              totalStatuses={totalStatuses}
              canSend={canSend}
              readOnly={readOnly}
              canAccess={canAccessCrew} />)
          })}
        </tbody>
      </table>
    </div>
  );
}

export default EmailRecipients;