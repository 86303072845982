import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchFilm, updateFilm, deleteFilm, archiveFilm, copyFilm, exportFilm, getCountries } from "./ducks/actions";
import { getUnions } from "../../app/ducks/actions";
import confirm from "../../shared/modal/confirm";
import FilmForm from "./form";
import Errors from "./../../shared/errors";
import Loader from "./../../shared/loader";
import Onboarding from './../../shared/onboarding/onboarding';
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import auth from "../../shared/auth";

const EditContainer = (props) => {
  const filmId = props.match.params.id;
  const { fetchFilm,
    updateFilm,
    deleteFilm,
    archiveFilm,
    copyFilm,
    film,
    loading,
    errors,
    onboardingSteps,
    currencies,
    languages,
    getCountries,
    countries,
    getUnions,
    actorUnions,
    crewUnions,
    initialValues,
    exportFilm } = props;

  useEffect(() => {
    if (filmId != "add") {
      fetchFilm(filmId);
    }
    getUnions();
    getCountries();
  }, []); // [] means the effect will run only once

  const [image, setImage] = useState<any>();
  const [imageFileName, setImageFileName] = useState<any>();

  useEffect(() => {
    setImageFileName(film?.productionCompanyLogoFileName);
  }, [film?.productionCompanyLogoFileName]);

  if (filmId == "add") {
    return null;
  }

  function onUpdateFilm(updatedFilm) {

    const confirmUpdateCrewRates = (update: boolean) => {
      confirm(() => {
        updatedFilm.updateCrewRates = update;
        sanitiseAndUpdateFilm(updatedFilm);
      }, null, "Do you want to apply union rates to crew roles without rates set?", "Crew Union", () => sanitiseAndUpdateFilm(updatedFilm))
    };

    let justUpdate = true;
    updatedFilm.updateCharacterRates = false;
    updatedFilm.updateCrewRates = false;
    if (updatedFilm.actorUnion && !film.actorUnion) {
      justUpdate = false;
      // actor union added, so offer to change all current character rates
      confirm(() => {
        updatedFilm.updateCharacterRates = true;
        if (updatedFilm.crewUnion && !film.crewUnion) {
          setTimeout(() => confirmUpdateCrewRates(true), 100);
        } else {
          sanitiseAndUpdateFilm(updatedFilm);
        }
      }, null, "Do you want to apply union rates to characters and background characters without rates set?", "Actor Union", () => {
        if (updatedFilm.crewUnion && !film.crewUnion) {
          setTimeout(() => confirmUpdateCrewRates(true), 100);
        } else {
          sanitiseAndUpdateFilm(updatedFilm);
        }
      }
      );
    } else if (updatedFilm.crewUnion && !film.crewUnion) {
      justUpdate = false;
      // crew union added, so offer to change all current crew rate
      confirmUpdateCrewRates(true);
    }

    if (justUpdate) {
      sanitiseAndUpdateFilm(updatedFilm);
    }
  }

  const sanitiseAndUpdateFilm = (updatedFilm) => {
    updatedFilm.budget = updatedFilm.budget ? +updatedFilm.budget : null;
    updatedFilm.length = updatedFilm.length ? +updatedFilm.length : null;
    updatedFilm.frameRate = updatedFilm.frameRate ? +updatedFilm.frameRate : null;
    updatedFilm.filmType = +updatedFilm.filmType;
    updatedFilm.status = +updatedFilm.status;
    updatedFilm.automaticallySendCallSheets = updatedFilm.automaticallySendCallSheets ?? false;
    updatedFilm.hoursBeforeToSendCallSheet = +updatedFilm.hoursBeforeToSendCallSheet;

    if (!updatedFilm.aspectRatioId) {
      updatedFilm.aspectRatioId = null;
    }

    updateFilm(updatedFilm, image);
  };

  function onDeleteFilm() {
    confirm(() => deleteFilm(film), film?.name);
  }

  function onCopyFilm() {
    copyFilm(filmId);
  }

  function onArchiveFilm() {
    confirm(() => archiveFilm(film), film?.name, `Are you sure you want to archive ${film?.name}? Once Archived you cannot un-archive and will be read only.`, "Archive");
  }

  function showFile(blob, filmName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should    
    var newBlob = new Blob([blob], { type: "txt/json" });

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;

    link.download = filmName + ".json";
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  const onExportFilm = () => {
    const url = (process.env.REACT_APP_API_URL || (window as any).apiUrl) + "film"
    fetch(`${url}/${film.id}/export`, {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + auth.getToken()
      })
    })
      .then((r) => r.blob())
      .then((blob) => {
        showFile(blob, film.name);
      })
  };

  function renderFilm(film) {
    return (
      <ScrollIntoView
        className="blade-content wide"
        path="/films/:filmId/edit"
        loading={loading}>
        <h2 className="menu-label">Edit Film</h2>
        {errors && <Errors errors={errors} />}
        <FilmForm
          onSubmit={onUpdateFilm}
          handleDelete={onDeleteFilm}
          handleArchive={onArchiveFilm}
          handleCopy={onCopyFilm}
          handleExport={onExportFilm}
          mode="edit"
          currencies={currencies}
          languages={languages}
          countries={countries}
          actorUnions={actorUnions}
          crewUnions={crewUnions}
          filmId={filmId}
          setImage={setImage}
          imageFileName={imageFileName}
          canAddProductionCompanyLogo={film?.canAddProductionCompanyLogo}
          canChangeFilmType={false}
          initialValues={film}
          showExport={film?.isAdmin}
          {...props}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Film" />
      </ScrollIntoView>
    );
  }

  if (props.redirect) {
    return <Redirect to="/films" />;
  }

  return (
    <>
      {(loading) && <Loader />}
      {!loading && renderFilm(film)}
    </>
  );
};

function mapStateToProps(state) {
  const { filmsState, commonState } = state;
  return {
    errors: filmsState.errors,
    loading: filmsState.loading,
    film: filmsState.film,
    redirect: filmsState.redirect,
    onboardingSteps: filmsState.editFilmOnboardingSteps,
    aspectRatios: commonState.aspectRatios,
    filmTypes: commonState.filmTypeEnum,
    filmStatuses: commonState.filmStatusEnum,
    genreTypes: commonState.genreTypes,
    frameRates: commonState.frameRates,
    resolutions: commonState.resolutions,
    currencies: commonState.currencies,
    languages: commonState.languages,
    countries: filmsState.countries,
    actorUnions: commonState.actorUnions,
    crewUnions: commonState.crewUnions
  };
}

export default connect(mapStateToProps, {
  fetchFilm, updateFilm, deleteFilm, archiveFilm,
  getUnions, copyFilm,
  exportFilm,
  getCountries
})(
  EditContainer
);
