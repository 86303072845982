import React from "react";
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Errors from "../../../shared/errors";
import {
    OnboardingStep1ClassName,
    OnboardingStep2ClassName
} from "./ducks/reducer";
import OverviewActor from './overview-item';
import { Prompt } from "react-router-dom";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";

function ActorOverview(props) {
    const {
        actors,
        filmId,
        errors,
        warnings,
        readOnly,
        updateActorSignedRelease
    } = props;


    const actorsWithCharacters = actors.filter(a => a.characters != null && a.characters.length > 0);
    const totalActorsWithCharacters = actorsWithCharacters.length;
    const actorsWithSignedRelease = actorsWithCharacters.filter(c => c.signedRelease);
    const actorsWithSignedReleaseCount = actorsWithSignedRelease.length;
    const actorsSignedReleasePercentage = totalActorsWithCharacters == 0
        ? 0
        : (actorsWithSignedReleaseCount / totalActorsWithCharacters) * 100;


    return (
        <div>
            {errors && <Errors errors={errors} />}
            {warnings && <Errors errors={warnings} isWarning={true} />}
            <div className="columns">
                <div
                    className={`${OnboardingStep1ClassName} circular-progress column is-one-quarter`}>
                    <FilmalityCircularProgress
                        percentage={actorsSignedReleasePercentage}
                        title="Signed Release"
                    />
                </div>
            </div>

            <div>
                <table className={`table`}>
                    <thead>
                        <tr>
                            <th>Actor</th>
                            <th>Characters</th>
                            <th>Signed Release</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(actors || []).map((actor: any, index: number) => (
                            <OverviewActor key={actor.id} actor={actor} index={index} readOnly={readOnly} filmId={filmId} updateActorSignedRelease={updateActorSignedRelease} />
                        ))}


                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default ActorOverview;
