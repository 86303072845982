import React, { useEffect, useState } from "react";
import "./small-loader.scss";

const SmallLoader = (props) => {
  const { message, className } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return <div className={`small-loader-wrapper ${!isLoading ? "" : "is-active"} ${className}`}>
    <div className="small-loader is-loading">
    </div>
    {message && <span className="small-loader-message">{message}</span>}
  </div>;
};

export default SmallLoader;
