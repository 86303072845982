import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
    fetchActorOverview,
    updateActorSignedRelease
} from "./ducks/actions";
import Onboarding from '../../../shared/onboarding/onboarding';
import Overview from "./overview";
import './actor-overview.scss'

const OverviewContainer = (props) => {
    const filmId = props.match.params.filmId;
    const {
        fetchActorOverview,
        updateActorSignedRelease,
        onboardingSteps,
        actors,
        actorsSignedReleasePercentage,
        loading,
        errors,
        warnings,
        readOnly,
        pristine
    } = props;

    useEffect(() => {
        fetchActorOverview(filmId);

        return function cleanUp() {
            //clearCharacterOverview();
        };
    }, [filmId]);

    const onUpdateActorSignedRelease = (actor, signed) => {
        if (readOnly) {
            return;
        }

        updateActorSignedRelease(filmId, actor, signed);
    }

    function renderOverview(actors) {
        return (
            <ScrollIntoView
                className="blade-content wider"
                path="/films/:filmId/actors/overview"
                loading={loading}>
                <h2 className="menu-label">Actors Overview</h2>
                <Overview
                    actors={actors}
                    actorsSignedReleasePercentage={actorsSignedReleasePercentage}
                    filmId={filmId}
                    errors={errors}
                    warnings={warnings}
                    readOnly={readOnly}
                    onboardingSteps={onboardingSteps}
                    pristine={pristine}
                    updateActorSignedRelease={onUpdateActorSignedRelease} />
                <Onboarding onboardingSteps={onboardingSteps} section="Actors Overview" />
            </ScrollIntoView>
        );
    }

    return (
        <>
            {(loading || !actors) && <Loader />}
            {!loading && actors && renderOverview(actors)}
        </>
    );
};

function mapStateToProps(state) {
    const { errors, warnings, actors, actorsSignedReleasePercentage, loading, onboardingSteps, readOnly,
        pristine } =
        state.actorsState.overviewState;
    return {
        errors,
        warnings,
        actors,
        loading,
        onboardingSteps,
        readOnly,
        pristine,
        actorsSignedReleasePercentage
    };
}

export default connect(mapStateToProps, {
    fetchActorOverview,
    updateActorSignedRelease
})(OverviewContainer);
