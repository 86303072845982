import { Link } from "react-router-dom";
import auth from "../../../shared/auth";
import SmallLoader from "../../../shared/small-loader/small-loader";
import { OnboardingStep2ClassName } from "./ducks/reducer";

function OverviewBackgroundActor(props) {
    const {
        actor,
        index,
        readOnly,
        updateBackgroundActorSignedRelease,
        filmId
    } = props;

    return (
        <tr>
            <td>
                <div className="actor-overview-row">
                    <Link className="actor-overview-row" to={`${actor.id}/edit`}>{actor.name}</Link>
                </div>
                {actor.mainImageUrl && <Link to={`${actor.id}/edit`}><img width={100} className="actor-overview-image" src={`${actor.mainImageUrl}?t=${auth.getImageToken()}`}></img></Link>}
            </td>
            <td>
                {(actor.backgroundCharacters || []).map((backgroundCharacter, index) => <><Link to={`/films/${filmId}/background-characters/${backgroundCharacter.id}/edit`}>{backgroundCharacter.name}</Link>{index < actor.backgroundCharacters.length - 1 ? ", " : ""}</>)}
            </td>
            <td className={`actor-overview-signed-column ${index === 0 ? OnboardingStep2ClassName : ""}`}>
                {actor.updating && <SmallLoader className="actor-overview-loader" />}
                {!actor.updating && actor.signedRelease && <i className={`material-icons call-sheet-not-signed-release-icon ${readOnly ? "" : "is-clickable"}`} title="Signed release" onClick={() => updateBackgroundActorSignedRelease(actor, false)}>edit</i>}
                {!actor.updating && !actor.signedRelease && <i className={`material-icons call-sheet-not-signed-release-icon ${readOnly ? "" : "is-clickable"}`} title="Not signed release" onClick={() => updateBackgroundActorSignedRelease(actor, true)}>edit_off</i>}
            </td>
        </tr>
    );
}

export default OverviewBackgroundActor;
