import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  addMember,
  clearCrewMember,
  clearRedirect,
  uploadImagesAndSaveCrewMember
} from "./ducks/actions";
import Form from "./form";
import Loader from "../../../../../shared/loader";
import ScrollIntoView from "../../../../../shared/scroll-into-view/scroll-into-view";

function AddMember(props) {
  const { filmId, departmentId, roleId } = props.match.params;
  const {
    uploadImagesAndSaveCrewMember,
    addMember,
    clearCrewMember,
    member,
    errors,
    loadingMember,
    languageHasGender,
    genderEnum,
    drinks,
    milkTypes,
    showFilmdUrl
  } = props;

  useEffect(() => {
    clearCrewMember();
  }, []);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/departments/${departmentId}/roles/${roleId}/members`;
      return <Redirect to={url} />;
    }
  }

  function onAddMember(member) {
    sanitiseDataTypes(member);
    member.roleIds = [roleId];

    const files = member.pictures || [];

    if (files && files.length > 0) {
      uploadImagesAndSaveCrewMember(
        member,
        files,
        true,
        filmId,
        departmentId,
        roleId
      );
    } else {
      if (!member.imageUrls) {
        member.imageUrls = [];
      }
      addMember(filmId, departmentId, roleId, member);
    }
  }

  function sanitiseDataTypes(member) {
    let dietaryRequirement = 0;
    (member.dietaryRequirementsArray || []).forEach((item) => {
      dietaryRequirement = dietaryRequirement | item;
    });

    member.dietaryRequirements = dietaryRequirement;
  }

  if (props.loadingMember) {
    return <Loader />;
  }

  const phoneNumberRequired = props.location.state.phoneNumberRequired;

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/departments/:departmentId/roles/:roleId/members/add"
      loading={loadingMember ? true : false}>
      <Form
        mode="add"
        errors={errors}
        memberId={null}
        initialValues={member || {}}
        loading={false}
        onSubmit={onAddMember}
        phoneNumberRequired={phoneNumberRequired}
        genderEnum={genderEnum}
        languageHasGender={languageHasGender}
        drinks={drinks}
        milkTypes={milkTypes}
        showFilmdUrl={showFilmdUrl}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { crewMembersState, commonState, filmsState } = state;
  return {
    dietaryRequirementEnum: commonState.dietaryRequirementEnum,
    genderEnum: commonState.genderEnum,
    languageHasGender: filmsState.userFilm?.languageHasGender,
    member: crewMembersState.member,
    showFilmdUrl: crewMembersState.showFilmdUrl,
    phoneNumberRequired: crewMembersState.phoneNumberRequired,
    errors: crewMembersState.errors,
    redirect: crewMembersState.redirect,
    loadingMember: crewMembersState.loadingMember,
    drinks: state.commonState.drinks,
    milkTypes: state.commonState.milkTypes
  };
}

export default connect(mapStateToProps, {
  addMember,
  clearCrewMember,
  clearRedirect,
  uploadImagesAndSaveCrewMember
})(AddMember);
