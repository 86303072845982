import React, { useRef } from "react";
import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Errors from "../../../shared/errors";
import {
    renderCheckbox,
    renderCheckboxGroup, renderCkEditorField,
} from "../../../shared/form-helpers";
import required from "../../../shared/validation";
import {
    OnboardingStep1ClassName,
    OnboardingStep2ClassName,
    OnboardingStep3ClassName,
    OnboardingStep4ClassName,
    OnboardingStep5ClassName,
    OnboardingStep6ClassName,
    ShootingDayActor,
    ShootingDayBackgroundActor,
    ShootingDayCrewMember
} from './ducks/reducer';
import Download from "../../../shared/download";
import confirm from "../../../shared/modal/confirm";
import Progress from "../../../shared/progress";
import EmailRecipients from "./email-recipients";
import WarningText from "../../../shared/warning-text";

const ShootingDayCallSheetForm = (props: any) => {
    const { handleSubmit,
        pristine,
        reset,
        submitting,
        errors,
        readOnly,
        callSheetSectionEnum,
        weatherSectionEnum,
        callSheetSectionsListValue,
        downloadProgress,
        clearPdf,
        download,
        onDownloadCallSheet,
        onSendCallSheetToSelf,
        onSendCallSheetToMembers,
        onCancelShootingDay,
        dates,
        progress,
        filmId,
        onSendPerson,
        shootingDaySettings,
        includeConfirmationValue,
        cancelled,
        canCancel,
        canAccessActors,
        canAccessBackgroundActors,
        canAccessCrew,
        hasLocation } = props;

    const formRef = useRef<any>();
    const submitter = props.handleSubmit(props.onSubmit);
    const onOnSendCallSheetToSelf = () => {
        if (!pristine) {
            props.change("sendToSelf", true);
            setTimeout(() => submitter(), 1);
        } else {
            onSendCallSheetToSelf();
        }
    };

    const onOnSendCallSheetToMembers = () => {
        if (!pristine) {
            props.change("sendToMembers", true);
            setTimeout(() => submitter(), 1);
        } else {
            onSendCallSheetToMembers();
        }
    };

    const onOnCancelShootingDay = () => {
        confirm(() => {
            onCancelShootingDay();

        }, null, `Are you sure you want to cancel the shooting day? An email will be sent to all actors and crew informing them it has been cancelled.`, "Cancel");
    };

    const onOnSendPerson = (personId, personType, fullName, resend) => {
        confirm(() => {
            if (!pristine) {
                props.change("sendToPerson", { personId, personType });
                setTimeout(() => submitter(), 1);
            } else {
                onSendPerson(personId, personType);
            }
        }, null, `Are you sure you want to ${resend ? "re" : ""}send call sheet email to ${fullName}?`, "Confirmation");

    };

    let thisSettings = props.initialValues || {};
    const showWeather = callSheetSectionsListValue?.some(v => v === 2);

    return (
        <>
            <Prompt when={!pristine} message="" />
            {errors && <Errors errors={errors} />}
            <form ref={formRef} autoComplete="off" onSubmit={handleSubmit}>
                <label className="label">Top Section</label>
                <div>
                    <Field
                        name="includeProducers"
                        label="Include Producers"
                        component={renderCheckbox}
                        readOnly={readOnly}
                    />
                </div>
                <div className={`mb-1`}>
                    <Field
                        name="includeDirectors"
                        label="Include Directors"
                        component={renderCheckbox}
                        readOnly={readOnly}
                    />
                </div>
                <div className="columns">
                    <div className="column">
                        <div className={`call-sheet-sections-list  ${OnboardingStep1ClassName}`}>
                            <Field
                                name="callSheetSectionsList"
                                label="Call Sheet Sections"
                                component={renderCheckboxGroup}
                                value={thisSettings.callSheetSections}
                                options={callSheetSectionEnum}
                                textProperty="name"
                                valueProperty="value"
                                readOnly={readOnly}
                            />
                        </div>
                    </div>
                    <div className="column">
                        {showWeather && <>
                            <div className={OnboardingStep2ClassName}>
                                <Field
                                    name="weatherSectionsList"
                                    label="Weather Elements"
                                    component={renderCheckboxGroup}
                                    options={weatherSectionEnum}
                                    value={thisSettings.weatherSections}
                                    textProperty="name"
                                    valueProperty="value"
                                    readOnly={readOnly}
                                />
                            </div>
                        </>}
                    </div>
                </div>
                <div className={`mb-1 ${OnboardingStep3ClassName}`}>
                    <Field
                        name="emailBody"
                        label="Email Extra Information"
                        component={renderCkEditorField}
                        readOnly={readOnly}
                    />
                </div>
                <div className={`mb-1 ${OnboardingStep4ClassName}`}>
                    <Field
                        name="includeConfirmation"
                        label="Include Confirmation link in email"
                        component={renderCheckbox}
                        readOnly={readOnly}
                    />
                </div>
                <div className={`mb-1 ${OnboardingStep5ClassName}`}>
                    <Field
                        name="includeSides"
                        label="Include Script Sides"
                        component={renderCheckbox}
                        readOnly={readOnly}
                    />
                </div>
                <div className={`mb-1 ${OnboardingStep6ClassName}`}>
                    <Field
                        name="includeCrewShotList"
                        label="Include Shot List for Crew"
                        component={renderCheckbox}
                        readOnly={readOnly}
                    />
                </div>
                {!readOnly && <div className="buttons">
                    <button
                        type="submit"
                        className="button is-primary"
                        disabled={pristine || submitting}>
                        Save
                    </button>
                </div>}
            </form>

            <div className="buttons mt-2">
                <Download
                    progress={downloadProgress}
                    clearPdf={clearPdf}
                    download={download}
                    errors={errors}
                    downloadFile={onDownloadCallSheet}></Download>
            </div>
            <div className="buttons">
                {!readOnly && <button className="button is-primary mb-1" onClick={onOnSendCallSheetToSelf}>
                    Email to yourself
                </button>}
                {shootingDaySettings?.canSendToOthers && !readOnly &&
                    <button
                        className="button is-primary mb-1"
                        onClick={onOnSendCallSheetToMembers}>
                        {dates?.callSheetSentDateTime && !cancelled && (
                            <span>Resend to actors and crew</span>
                        )}
                        {(!dates?.callSheetSentDateTime || cancelled) && (
                            <span>Email to actors and crew</span>
                        )}
                    </button>}

                {canCancel && !readOnly && <button
                    className="button is-primary mb-1"
                    onClick={onOnCancelShootingDay}>
                    Cancel
                </button>}
            </div>
            {!hasLocation &&
                <WarningText>Cannot send to others without a location.</WarningText>
            }

            {!shootingDaySettings?.hasDate &&
                <WarningText>Cannot send to others without a date.</WarningText>
            }

            {shootingDaySettings?.hasDate && <>
                <div className="mb-1">
                    <Progress progress={progress} />
                </div>

                {shootingDaySettings &&
                    <EmailRecipients shootingDaySettings={shootingDaySettings}
                        filmId={filmId}
                        onSendPerson={onOnSendPerson}
                        includeClicked={includeConfirmationValue}
                        canSend={shootingDaySettings?.canSendToOthers}
                        actorType={ShootingDayActor}
                        backgroundActorType={ShootingDayBackgroundActor}
                        crewMemberType={ShootingDayCrewMember}
                        totalStatuses={includeConfirmationValue ? 6 : 5}
                        clickedName="Confirmed"
                        readOnly={readOnly}
                        canAccessActors={canAccessActors}
                        canAccessBackgroundActors={canAccessBackgroundActors}
                        canAccessCrew={canAccessCrew} />}
            </>}
        </>
    )
};

let form = reduxForm({
    form: "shootingDayCallSheetForm",
    enableReinitialize: true
})(ShootingDayCallSheetForm);

const selector = formValueSelector('shootingDayCallSheetForm') // <-- same as form name
form = connect(
    state => {
        // can select values individually
        const callSheetSectionsListValue = selector(state, 'callSheetSectionsList')
        const includeConfirmationValue = selector(state, 'includeConfirmation')
        return {
            callSheetSectionsListValue,
            includeConfirmationValue
        }
    }
)(form)

export default form;