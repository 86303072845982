import getApiClient from "./../../../../shared/api";
import { showInfo, showError, showWarning, clearNotifications } from "./../../../../notifications/ducks/actions";

export const FETCH_ACTOR_OVERVIEW = "FETCH_ACTOR_OVERVIEW";
export const UPDATE_ACTOR_SIGNED_RELEASE = "UPDATE_ACTOR_SIGNED_RELEASE";

export function fetchActorOverview(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ACTOR_OVERVIEW,
      payload: getApiClient().get(`/film/${filmId}/actor/overview`),
    });
  };
}

export function updateActorSignedRelease(filmId: any, actor: any, signed: boolean) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTOR_SIGNED_RELEASE,
      payload: getApiClient().put(`/film/${filmId}/actor/${actor.id}/signedRelease`, { signed }),
      meta: { actor, signed }
    })
      .then((response) => {
        dispatch(showInfo(`Updated actor ${actor.name} signed release`));

        if (response.value.data.hasWarnings) {
          dispatch(showWarning(`Warning updating actor ${actor.name} signed release`));
        }
      })
      .catch((response) => {
        dispatch(showError(`Error updating actor ${actor.name} signed release`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
