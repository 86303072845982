import getApiClient from "./../../../../shared/api";
import { showInfo, showError, showWarning, clearNotifications } from "./../../../../notifications/ducks/actions";

export const FETCH_BACKGROUND_ACTOR_OVERVIEW = "FETCH_BACKGROUND_ACTOR_OVERVIEW";
export const UPDATE_BACKGROUND_ACTOR_SIGNED_RELEASE = "UPDATE_BACKGROUND_ACTOR_SIGNED_RELEASE";

export function fetchBackgroundActorOverview(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_BACKGROUND_ACTOR_OVERVIEW,
      payload: getApiClient().get(`/film/${filmId}/backgroundActor/overview`),
    });
  };
}


export function updateBackgroundActorSignedRelease(filmId: any, backgroundActor: any, signed: boolean) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BACKGROUND_ACTOR_SIGNED_RELEASE,
      payload: getApiClient().put(`/film/${filmId}/backgroundActor/${backgroundActor.id}/signedRelease`, { signed }),
      meta: { backgroundActor, signed }
    })
      .then((response) => {
        dispatch(showInfo(`Updated actor ${backgroundActor.name} signed release`));

        if (response.value.data.hasWarnings) {
          dispatch(showWarning(`Warning updating background actor ${backgroundActor.name} signed release`));
        }
      })
      .catch((response) => {
        dispatch(showError(`Error updating background actor ${backgroundActor.name} signed release`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
