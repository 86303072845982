import { parseTimeToMinutes } from "../../../../shared/format-time";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { SHOT_LIST_INCLUDE_EQUIPMENT, SHOT_LIST_INCLUDE_IMAGES } from "./actions";

interface ShootingDayShotListScheduleState {
  shotListSchedule: any[];
  locations: any[];
  scenes: any[];
  loading: boolean;
  saving: boolean;
  pristine: boolean;
  errors: any;
  download: any;
  selectedShotIds: any[];
  draggingShotId: any;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  crewEventDesciption: string | null;
  mealTimeSlot: boolean;
  includeImages: boolean;
  includeEquipment: boolean;
}

export const OnboardingStep1ClassName = 'shot-list-schedule-onboarding-step-1';
export const OnboardingStep2ClassName = 'shot-list-schedule-onboarding-step-2';
export const OnboardingStep3ClassName = 'shot-list-schedule-onboarding-step-3';
export const OnboardingStep4ClassName = 'shot-list-schedule-onboarding-step-4';
export const OnboardingStep5ClassName = 'shot-list-schedule-onboarding-step-5';
export const OnboardingStep6ClassName = 'shot-list-schedule-onboarding-step-6';
export const OnboardingStep7ClassName = 'shot-list-schedule-onboarding-step-7';
export const OnboardingStep8ClassName = 'shot-list-schedule-onboarding-step-8';
export const OnboardingStep9ClassName = 'shot-list-schedule-onboarding-step-9';
export const OnboardingStep10ClassName = 'shot-list-schedule-onboarding-step-10';
export const OnboardingStep11ClassName = 'shot-list-schedule-onboarding-step-11';
export const OnboardingStep12ClassName = 'shot-list-schedule-onboarding-step-12';

const defaultState: ShootingDayShotListScheduleState = {
  shotListSchedule: [],
  locations: [],
  scenes: [],
  loading: false,
  saving: false,
  pristine: true,
  errors: null,
  selectedShotIds: [],
  draggingShotId: null,
  download: null,
  readOnly: false,
  crewEventDesciption: null,
  mealTimeSlot: false,
  includeImages: false,
  includeEquipment: true,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Time Slot',
      content: `        
      First create a scene event – do this by adding a Time Slot to the scene and assign it a Scene Setting. Next you'll add shots to this
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Shots',
      content: `<p>
      Drag and drop shots from the shot list onto the Time Slot you just created to link them. Shots must have the same Scene Setting as the Time Slot. You can select and assign multiple shots by holding down ctrl/command and clicking each shot.
    </p>
    <p>
      The time after the scene description in brackets is the estimated shoot duration to help guide how long you should allow for shooting the scene.
    </p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Quck Selection',
      content: `Use the Quick Selection box to select multiple shots that meet the chosen criteria. 
                For example, you could select all the shots with the same lens, to minimise lens changing on set. 
                The selected shots will be added together.
                `
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Edit Time Slot',
      content: `Click this icon to edit the time of the Time Slot.`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Delete Time Slot',
      content: `Click this icon to delete the Time Slot. All shots assign to it will be returned to the list of unassigned shots on the left.`
    },
    {
      target: '.' + OnboardingStep6ClassName,
      title: 'Reorder Shots',
      content: `You can drag and drop Shots into a different order within a Time Slot, or drag them into a different Time Slot, as long as the Scene Setting for both the Shot and the Time Slot is the same.`
    },
    {
      target: '.' + OnboardingStep7ClassName,
      title: 'Delete Shot',
      content: `Click here to remove a shot from the Time Slot and return it to the list of available shots on the left.`
    },
    {
      target: '.' + OnboardingStep8ClassName,
      title: 'Crew Event',
      content: `Here you can add crew events, and give them a description (e.g. Setup, Strike).`
    },
    {
      target: '.' + OnboardingStep9ClassName,
      title: 'Meals',
      content: `A crew event flagged as a meal will be picked up as a key events; key events are added to the top of the call sheet.`
    },
    {
      target: '.' + OnboardingStep10ClassName,
      title: 'Download',
      content: ` <p>
      Click "Download" to download the Shot List as a PDF, ready to print and use when shooting.
    </p>`
    },
    {
      target: '.' + OnboardingStep11ClassName,
      title: 'Images',
      content: ` <p>
      You can optionally include the shot images in the download as well.
    </p>`
    },
    {
      target: '.' + OnboardingStep12ClassName,
      title: 'Email',
      content: `<p>Or click here to email a copy of the shot list to yourself.</p>
      <p>You also have the option to include the shot list in the call sheet email to the crew from the call sheet page.</p>
                `
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTINGDAY_SHOT_LIST_SCHEDULE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SHOOTINGDAY_SHOT_LIST_SCHEDULE_FULFILLED": {
      return {
        ...state,
        shotListSchedule: action.payload.data.schedule,
        scenes: action.payload.data.scenes,
        locations: action.payload.data.locations,
        sceneSettings: action.payload.data.sceneSettings,
        crewCallHour: action.payload.data.crewCallHour,
        estimatedWrapHour: action.payload.data.estimatedWrapHour + (action.payload.data.estimatedWrapTime.days * 24),
        estimatedWrapTime: action.payload.data.estimatedWrapTime,
        readOnly: action.payload.data.readOnly,
        includeImages: action.payload.data.includeImages,
        includeEquipment: action.payload.data.includeEquipment,
        canAccessScenes: action.payload.data.canAccessScenes,
        canAccessShots: action.payload.data.canAccessShots,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case "FETCH_SHOOTINGDAY_SCENES_FULFILLED": {
      return {
        ...state,
        scenes: action.payload.data.scenes
      };
    }

    case "FETCH_SHOOTINGDAY_SCENE_DETAILS_FULFILLED": {
      let shots = action.payload.data.shots.map((s) => {
        s.name = `${s.shotNumber}-${s.shotDescription}`;
        return s;
      });

      return {
        ...state,
        selectedSceneDetails: { ...action.payload.data, shots: shots },
        errors: null
      };
    }

    case "FETCH_SHOOTINGDAY_SCENE_DETAILS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        selectedSceneDetails: null,
        loadingScheduleEvent: false
      };
    }

    case "ADD_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_PENDING": {
      return {
        ...state,
        event: action.payload,
        errors: null,
        loadingScheduleEvent: true
      };
    }

    case "UPDATE_SINGLE_SHOT_LIST_SCHEDULE_PENDING": {
      return {
        ...state,
        errors: null,
        saving: true
      };
    }

    case "UPDATE_SINGLE_SHOT_LIST_SCHEDULE_FULFILLED": {
      return {
        ...state,
        saving: false,
        pristine: true
      };
    }

    case "UPDATE_SINGLE_SHOT_LIST_SCHEDULE_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        saving: false
      };
    }

    case "SHOOTINGDAY_SCHEDULE_EVENT_UPDATE_SHOT_ORDER": {
      const sourceIndex = action.meta.sourceIndex;
      const destinationIndex = action.meta.destinationIndex;
      const sourceDroppableId = action.meta.sourceDroppableId;
      const destinationDroppableId = action.meta.destinationDroppableId;
      let shotListSchedule = Array.from(state.shotListSchedule);

      if (sourceDroppableId == destinationDroppableId) {
        if (isGuid(sourceDroppableId)) {
          return state;
        }
        let timeSlot = shotListSchedule.find(
          (s) => {
            const splitTime = destinationDroppableId.split(":");
            return s.startTime.days == +splitTime[0] && s.startTime.hours == +splitTime[1] && s.startTime.minutes == +splitTime[2];
          }
        );
        timeSlot.shots = reorderScenes(
          timeSlot.shots,
          sourceIndex,
          destinationIndex
        );
        return {
          ...state,
          shotListSchedule,
          pristine: false,
          download: null
        };
      } else if (
        !isGuid(sourceDroppableId) &&
        !isGuid(destinationDroppableId)
      ) {
        // moved between timeslots
        let sourceTimeSlot = shotListSchedule.find(
          (s) => {
            const splitTime = sourceDroppableId.split(":");
            return s.startTime.days == +splitTime[0] && s.startTime.hours == +splitTime[1] && s.startTime.minutes == +splitTime[2];
          }
        );

        if (sourceTimeSlot == null) {
          debugger;
        }

        let destinationTimeSlot = shotListSchedule.find(
          (s) => {
            const splitTime = destinationDroppableId.split(":");
            return s.startTime.days == +splitTime[0] && s.startTime.hours == +splitTime[1] && s.startTime.minutes == +splitTime[2];
          }
        );

        if (destinationTimeSlot == null) {
          debugger;
        }

        const original = sourceTimeSlot.shots.splice(sourceIndex, 1);
        const newDestinationScene: any = original[0];

        destinationTimeSlot.shots.splice(
          destinationIndex,
          0,
          newDestinationScene
        );

        calculateTotalDurationMinutes(sourceTimeSlot);
        calculateTotalDurationMinutes(destinationTimeSlot);

        return {
          ...state,
          shotListSchedule,
          pristine: false,
          download: null
        };
      } else if (isGuid(sourceDroppableId)) {
        // from scenes to time slot
        let scenes = [...state.scenes];
        let timeSlot = shotListSchedule.find(
          (s) => {
            const splitTime = destinationDroppableId.split(":");
            return s.startTime.days == +splitTime[0] && s.startTime.hours == +splitTime[1] && s.startTime.minutes == +splitTime[2];
          }
        );
        const selectedShotIds = state.selectedShotIds;
        if (state.selectedShotIds.length > 1) {
          let shots: any[] = [];
          scenes.forEach((scene) => {
            scene.shots.forEach((shot, index) => {
              if (state.selectedShotIds.indexOf(shot.shotId) > -1) {
                shots.push(shot);
              }
            });
          });
          timeSlot.shots.splice(destinationIndex, 0, ...shots);

          scenes.forEach((scene) => {
            scene.shots = scene.shots.filter(
              (shot) => !selectedShotIds.includes(shot.shotId)
            );
          });
        } else {
          let sceneSource = scenes.find((s) => s.id == sourceDroppableId);
          const original = sceneSource.shots.splice(sourceIndex, 1);
          const newDestinationScene: any = original[0];
          timeSlot.shots.splice(destinationIndex, 0, newDestinationScene);
        }

        calculateTotalDurationMinutes(timeSlot);
        return {
          ...state,
          shotListSchedule,
          scenes,
          selectedShotIds: [],
          pristine: false,
          download: null
        };
      } else if (!isGuid(sourceDroppableId)) {
        // from timeslot back to scene

        let scenes = [...state.scenes];

        let sceneDestination = scenes.find(
          (s) => s.id == destinationDroppableId
        );
        let timeSlot = shotListSchedule.find(
          (s) => {
            const splitTime = sourceDroppableId.split(":");
            return s.startTime.days == +splitTime[0] && s.startTime.hours == +splitTime[1] && s.startTime.minutes == +splitTime[2];
          }
        );
        const original = timeSlot.shots.splice(sourceIndex, 1);
        const newDestinationScene: any = original[0];

        sceneDestination.shots.splice(destinationIndex, 0, newDestinationScene);

        return {
          ...state,
          shotListSchedule,
          scenes,
          pristine: false,
          download: null
        };
      } else {
        return state
      }
    }

    case "SHOOTINGDAY_SCHEDULE_EVENT_DELETE_SHOT": {
      let shotListSchedule = Array.from(state.shotListSchedule);
      let scenes = Array.from(state.scenes);
      const timeSlotStart = action.meta.timeSlot;
      const shotId = action.meta.shotId;
      const sceneId = action.meta.sceneId;

      let timeSlot = shotListSchedule.find(
        (s) => {
          return s.startTime.days == timeSlotStart.days && s.startTime.hours == timeSlotStart.hours && s.startTime.minutes == timeSlotStart.minutes;
        }
      );

      const shotIndex = timeSlot.shots.findIndex((s) => s.shotId == shotId);
      const shot = timeSlot.shots[shotIndex];

      timeSlot.shots.splice(shotIndex, 1);
      calculateTotalDurationMinutes(timeSlot);

      let sceneDestination = scenes.find((s) => s.id == sceneId);

      sceneDestination.shots.splice(0, 0, shot);
      sortShots(sceneDestination.shots);
      return {
        ...state,
        shotListSchedule,
        scenes,
        pristine: false,
        download: null
      };
    }

    case "ADD_SHOT_LIST_SCHEDULE_TIME_SLOT": {
      let shotListSchedule = Array.from(state.shotListSchedule);
      const startTime = action.meta.startTime;
      const existingTime = shotListSchedule.find(
        (s) => {
          return s.startTime.days == startTime.days && s.startTime.hours == startTime.hours && s.startTime.minutes == startTime.minutes;
        }
      );
      if (existingTime != null) {
        return {
          ...state,
          errors: { startTime: ["Cannot add duplicate time"] }
        };
      }

      const scene = action.meta.scene;

      shotListSchedule.push({
        type: 1,
        startTime: action.meta.startTime,
        endTime: action.meta.endTime,
        sceneSetting: action.meta.sceneSetting,
        scene: scene,
        shots: []
      });
      sortTimeSlots(shotListSchedule);
      return {
        ...state,
        shotListSchedule,
        errors: null,
        pristine: false,
        download: null
      };
    }

    case "UPDATE_SHOT_LIST_SCHEDULE_TIME_SLOT": {
      let shotListSchedule = Array.from(state.shotListSchedule);
      const { newTime, oldTime } = action.meta;
      if (newTime !== oldTime) {
        const existingTime = shotListSchedule.find(
          (s) => {
            return s.startTime.days == newTime.days && s.startTime.hours == newTime.hours && s.startTime.minutes == newTime.minutes;
          }
        );
        if (existingTime != null) {
          return {
            ...state,
            errors: { startTime: ["Cannot have duplicate time"] }
          };
        }
      }

      const timeToUpdate = shotListSchedule.find(
        (s) => {
          return s.startTime.days == oldTime.days && s.startTime.hours == oldTime.hours && s.startTime.minutes == oldTime.minutes;
        }
      );

      timeToUpdate.startTime = newTime;
      timeToUpdate.description = action.meta.description;
      sortTimeSlots(shotListSchedule);
      return {
        ...state,
        shotListSchedule,
        errors: null,
        pristine: false,
        download: null
      };
    }

    case "CREW_TIME_SLOT_DESCRIPTION_CHANGED": {
      return {
        ...state,
        crewEventDesciption: action.meta.description,
        download: null
      };
    }

    case "CREW_TIME_SLOT_MEAL_CHANGED": {
      return {
        ...state,
        mealTimeSlot: action.meta.mealTimeSlot,
        download: null
      };
    }

    case "ADD_SHOT_LIST_SCHEDULE_CREW_TIME_SLOT": {
      let shotListSchedule = Array.from(state.shotListSchedule);

      const startTime = action.meta.startTime;
      const existingTime = shotListSchedule.find(
        (s) => {
          return s.startTime.days == startTime.days && s.startTime.hours == startTime.hours && s.startTime.minutes == startTime.minutes;
        }
      );

      if (!action.meta.description) {
        return {
          ...state,
          errors: { description: ["Description cannot be empty"] }
        };
      }

      if (existingTime != null) {
        return {
          ...state,
          errors: { startTime: ["Cannot add duplicate time"] }
        };
      }
      shotListSchedule.push({
        type: action.meta.mealTimeSlot ? 2 : 3,
        startTime: action.meta.startTime,
        endTime: action.meta.endTime,
        description: action.meta.description,
        shots: []
      });
      sortTimeSlots(shotListSchedule);
      return {
        ...state,
        shotListSchedule,
        crewEventDesciption: "",
        mealTimeSlot: false,
        errors: null,
        pristine: false,
        download: null
      };
    }

    case "DELETE_SHOT_LIST_SCHEDULE_TIME_SLOT": {
      let shotListSchedule = Array.from(state.shotListSchedule);
      let scenes = Array.from(state.scenes);
      const timeSlotStart = action.meta.timeSlotStart;

      const timeSlotIndex = shotListSchedule.findIndex(
        (s) => {
          return s.startTime.days == timeSlotStart.days && s.startTime.hours == timeSlotStart.hours && s.startTime.minutes == timeSlotStart.minutes;
        }
      );
      let timeSlot = shotListSchedule[timeSlotIndex];

      timeSlot.shots.forEach((shot) => {
        let sceneDestination = scenes.find((s) => s.id == shot.sceneId);
        sceneDestination.shots.splice(0, 0, shot);
      });

      shotListSchedule.splice(timeSlotIndex, 1);

      scenes.forEach((scene) => {
        sortShots(scene.shots);
      });

      return {
        ...state,
        shotListSchedule,
        scenes,
        pristine: false,
        download: null
      };
    }

    case "SHOT_LIST_SCHEDULE_SELECT_SHOTS": {
      let scenes = Array.from(state.scenes);
      const sceneSettingId = action.meta.sceneSetting;
      const sceneId = action.meta.scene;
      const lens = action.meta.lens;
      const movement = action.meta.movement;
      const actor = action.meta.actor;
      let selectedShotIds: any[] = [];

      scenes.forEach((scene) => {
        if (scene.sceneSetting.id == sceneSettingId) {
          scene.shots.forEach((shot) => {
            const sceneValid = !sceneId || shot.sceneId == sceneId;
            const lensValid = !lens || shot.lensFocalLength == lens;
            const movementValid =
              !movement || shot.movements.search(movement) != -1;
            const actorValid = !actor || shot.actors.search(actor) != -1;
            if (lensValid && movementValid && actorValid && sceneValid) {
              selectedShotIds.push(shot.shotId);
            }
          });
        }
      });

      return {
        ...state,
        selectedShotIds
      };
    }

    case "SHOT_LIST_SCHEDULE_SELECT_ALL_SHOTS": {
      const sceneSettingId = action.meta.sceneSetting.value;
      let scenes = Array.from(state.scenes);

      let selectedShotIds: any[] = [];
      scenes.forEach((scene) => {
        if (scene.sceneSetting.id == sceneSettingId) {
          scene.shots.forEach((shot) => {
            selectedShotIds.push(shot.shotId);
          });
        }
      });

      return {
        ...state,
        selectedShotIds
      };
    }

    case "SHOT_LIST_SCHEDULE_TOGGLE_SELECTION": {
      const shotId = action.meta.shotId;

      let selectedShotIds = Array.from(state.selectedShotIds);
      const wasSelected: boolean = selectedShotIds.includes(shotId);

      const newShotIds = (() => {
        // Task was not previously selected
        // now will be the only selected item
        if (!wasSelected) {
          return [shotId];
        }

        // Task was part of a selected group
        // will now become the only selected item
        if (selectedShotIds.length > 1) {
          return [shotId];
        }

        // task was previously selected but not in a group
        // we will now clear the selection
        return [];
      })();

      return {
        ...state,
        selectedShotIds: newShotIds
      };
    }

    case "SHOT_LIST_SCHEDULE_TOGGLE_SELECTION_IN_GROUP": {
      const shotId = action.meta.shotId;

      let selectedShotIds = Array.from(state.selectedShotIds);
      const index: number = selectedShotIds.indexOf(shotId);

      // if not selected - add it to the selected items
      if (index === -1) {
        selectedShotIds = [...selectedShotIds, shotId];

        return {
          ...state,
          selectedShotIds
        };
      }

      // it was previously selected and now needs to be removed from the group
      const shallow = [...selectedShotIds];
      shallow.splice(index, 1);

      return {
        ...state,
        selectedShotIds: shallow
      };
    }

    case "SHOT_LIST_SCHEDULE_UNSELECT_ALL": {
      return {
        ...state,
        selectedShotIds: []
      };
    }

    case "SHOT_LIST_SCHEDULE_SET_DRAGGING_SHOTID": {
      const shotId = action.meta.shotId;
      return {
        ...state,
        draggingShotId: shotId
      };
    }

    case "SHOT_LIST_SCHEDULE_MULTI_SELECT_TO": {
      const shotId = action.meta.shotId;
      debugger;
      /*
      const updated: ?(Id[]) = multiSelect(
        this.state.entities,
        this.state.selectedTaskIds,
        newTaskId,
      );
  
      if (updated == null) {
        return;
      }
      */
      return {
        ...state
      };
    }

    case "DOWNLOAD_SHOT_LIST_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case "DOWNLOAD_SHOT_LIST_PENDING": {
      return {
        ...state,
        loading: false,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case "DOWNLOAD_SHOT_LIST_FULFILLED": {
      return state;
    }


    case "DOWNLOAD_SHOT_LIST_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case "DOWNLOAD_SHOT_LIST_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    case "CLEAR_SHOT_LIST_PDF": {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case "CLEAR_SHOT_LIST_DOWNLOAD": {
      return {
        ...state,
        download: null
      };
    }

    case "EMAIL_SHOT_LIST_PENDING": {
      return {
        ...state,
        saving: true
      };
    }

    case "EMAIL_SHOT_LIST_FULFILLED": {
      return {
        ...state,
        saving: false
      };
    }

    case "EMAIL_SHOT_LIST_REJECTED": {
      debugger;
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case SHOT_LIST_INCLUDE_IMAGES: {
      return {
        ...state,
        includeImages: action.meta.includeImages,
        pristine: false,
        download: null
      };
    }

    case SHOT_LIST_INCLUDE_EQUIPMENT: {
      return {
        ...state,
        includeEquipment: action.meta.includeEquipment,
        pristine: false,
        download: null
      };
    }

    default:
      return state;
  }
};

const calculateTotalDurationMinutes = (timeSlot) => {
  timeSlot.totalDurationMinutes = timeSlot.shots.reduce(function (a, b) {
    return a + b.estimatedShootingDurationMinutes;
  }, 0)
};

const reorderScenes = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  /*
  result[sourceIndex].scriptDayOrder = destinationIndex + 1;

  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      result[i].scriptDayOrder++;
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      result[i].scriptDayOrder--;
    }
  }
*/
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

function isGuid(value) {
  var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
  var match = regex.exec(value);
  return match != null;
}

function sortTimeSlots(timeSlots) {
  timeSlots.sort((a, b) => {
    if (parseTimeToMinutes(a.startTime) < parseTimeToMinutes(b.startTime)) return -1;
    if (parseTimeToMinutes(a.startTime) > parseTimeToMinutes(b.startTime)) return 1;
    return 0;
  });
}

function sortShots(shots) {
  shots.sort((a, b) => {
    if (a.shotNumber < b.shotNumber) return -1;
    if (a.shotNumber > b.shotNumber) return 1;
    return 0;
  });
}

export default reducer;
