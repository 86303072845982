import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  addActor,
  addImageFiles,
  addImageUrl,
  clearActor,
  clearRedirect,
  deleteImageFile,
  deleteImageUrl,
  fetchNextActorNumber,
  mainImageUrlChanged,
  parseMandyUrl,
  parseStarNowUrl,
  uploadImagesAndSaveActor
} from "./ducks/actions";
import Form from "./form";
import Loader from "../../shared/loader";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import Onboarding from '../../shared/onboarding/onboarding';

function AddActor(props) {
  const filmId = props.match.params.filmId;
  const {
    clearActor,
    fetchNextActorNumber,
    addActor,
    errors,
    actor,
    addImageFiles,
    addImageUrl,
    imageFiles,
    uploadImagesAndSaveActor,
    deleteImageUrl,
    deleteImageFile,
    mainImageUrlChanged,
    parseMandyUrl,
    parseStarNowUrl,
    onboardingSteps,
    drinks,
    milkTypes,
    showFilmdUrl
  } = props;

  useEffect(() => {
    clearActor();
    fetchNextActorNumber(filmId);
  }, [filmId]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/actors/`;
      return <Redirect to={url} />;
    }
  }

  function onAddActor(actor) {
    sanitiseDataTypes(actor);
    const files = actor.pictures || [];

    if (files && files.length > 0) {
      uploadImagesAndSaveActor(actor, files, true, filmId);
    } else {
      if (!actor.imageUrls) {
        actor.imageUrls = [];
      }
      addActor(filmId, actor);
    }
  }

  function sanitiseDataTypes(actor) {
    if (actor.dateOfBirth == '') {
      actor.dateOfBirth = null;
    }

    actor.number = +actor.number;
    actor.gender = +actor.gender;
    actor.heightCentimeters = actor.heightCentimeters
      ? +actor.heightCentimeters
      : null;
    actor.weightKilograms = actor.weightKilograms
      ? +actor.weightKilograms
      : null;

    let dietaryRequirement = 0;
    (actor.dietaryRequirementsArray || []).forEach((item) => {
      dietaryRequirement = dietaryRequirement | item;
    });

    actor.dietaryRequirements = dietaryRequirement;
  }

  const onAddImagesFiles = (files) => {
    addImageFiles(files);
  };

  const onAddImagesUrl = (url) => {
    addImageUrl(url);
  };

  const onMandyUrlChanged = (mandyUrl) => {
    if (mandyUrl) {
      parseMandyUrl(mandyUrl);
    }
  };

  const onStarNowUrlChanged = (starNowUrl) => {
    if (starNowUrl) {
      parseStarNowUrl(starNowUrl);
    }
  };

  if (props.loadingActor) {
    return <Loader />;
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/actors/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        actorId={null}
        initialValues={actor}
        loading={false}
        onSubmit={onAddActor}
        {...props}
        onAddImageFiles={onAddImagesFiles}
        onAddImageUrl={onAddImagesUrl}
        onDeleteImageUrl={deleteImageUrl}
        onDeleteImageFile={deleteImageFile}
        imageFiles={imageFiles}
        mainImageUrl={actor?.mainImageUrl}
        onMainImageUrlChanged={mainImageUrlChanged}
        onMandyUrlChanged={onMandyUrlChanged}
        onStarNowUrlChanged={onStarNowUrlChanged}
        addFromSource={props.source}
        filmId={filmId}
        drinks={drinks}
        milkTypes={milkTypes}
        showFilmdUrl={showFilmdUrl}
      />
      <Onboarding onboardingSteps={onboardingSteps} section="Actor" />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { commonState } = state;
  const actorsState = state.actorsState.rootState;
  return {
    actor: actorsState.actor,
    loadingActor: actorsState.loadingActor,
    onboardingSteps: actorsState.onboardingSteps,
    genderEnum: commonState.genderEnum,
    ethnicityEnum: commonState.ethnicityEnum,
    dietaryRequirementEnum: commonState.dietaryRequirementEnum,
    skinColors: commonState.skinColors,
    ethnicityTypes: commonState.ethnicityTypes,
    errors: actorsState.errors,
    imageFiles: actorsState.imageFiles,
    redirect: actorsState.redirect,
    showFilmdUrl: actorsState.showFilmdUrl,
    drinks: commonState.drinks,
    milkTypes: commonState.milkTypes
  };
}

export default connect(mapStateToProps, {
  addActor,
  clearActor,
  fetchNextActorNumber,
  addImageFiles,
  uploadImagesAndSaveActor,
  deleteImageUrl,
  deleteImageFile,
  mainImageUrlChanged,
  addImageUrl,
  parseMandyUrl,
  parseStarNowUrl,
  clearRedirect
})(AddActor);
