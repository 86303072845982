import React, { useEffect, useState } from "react";
import PageToEighth from "../../../shared/eighth";
import { Link } from "react-router-dom";

export default function EventDetails(props) {
  const { day, style, close, updateDate, readOnly, filmId } = props;

  const [date, setDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (day?.start) {
      setDate((new Date(day.start)).toISOString().split('T')[0]);
    }
  }, [day?.start]);

  if (!day) {
    return null;
  }

  const onSetDate = (event) => {
    setDate(event.target.value)
  };

  const onUpdateDate = () => {
    updateDate(day.id, date);
  };

  return (
    <div className="event-details" style={style}>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Scene</th>
            <th>Location</th>
            <th>Int/Ext</th>
            <th>Day/Night</th>
            <th>Pages</th>
            <th>Shoot Days</th>
          </tr>
        </thead>
        <tbody>
          {day.scenes.map((scene, index) => (
            <tr key={index}>
              <td><Link to={`/films/${filmId}/scenes/${scene.sceneId}/edit`}>{scene.number}</Link></td>
              <td><Link to={`/films/${filmId}/locations/${scene.locationId}/edit`}>{scene.locationName}</Link></td>
              <td>{scene.interiorExterior}</td>
              <td>{scene.dayNight}</td>
              <td><PageToEighth page={scene.pageLength}></PageToEighth></td>
              <td>{Math.round((scene.shootDays + Number.EPSILON) * 100) / 100}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>Day {day.number} <button className="button event-details-close-button" onClick={close}>Close</button></div>
      <div className="is-clearfix"></div>
      <div>Date <input type="date" className="input" value={date} onChange={onSetDate} disabled={!day.editable || readOnly} /></div>
      {!readOnly && <button className="button mb-1 mt-1" onClick={onUpdateDate} disabled={!day.editable}>Update Date</button>}

    </div>
  );
}
