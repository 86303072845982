import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { FETCH_BACKGROUND_ACTOR_OVERVIEW, UPDATE_BACKGROUND_ACTOR_SIGNED_RELEASE } from "./actions";

interface ActionState {
  backgroundActors: any[];
  loading: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  errors: any;
  pristine: boolean;
}

export const OnboardingStep1ClassName = 'backgorund-actor-overview-onboarding-step-1';
export const OnboardingStep2ClassName = 'backgorund-actor-overview-onboarding-step-2';

const defaultState: ActionState = {
  backgroundActors: [],
  loading: true,
  readOnly: false,
  errors: null,
  pristine: true,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Background Actor Overview',
      content: `        
        <p>The percentage of background actors who have signed a release.</p>        
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Background Actor Signed Release',
      content: `        
        <p>You can quickly mark a background actor as having signed the release by clicking this icon.</p>        
      `
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_BACKGROUND_ACTOR_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        pristine: true
      };
    }

    case FETCH_BACKGROUND_ACTOR_OVERVIEW + "_FULFILLED": {
      const sortedActors = action.payload.data.backgroundActors;
      sortByNumber(sortedActors);
      return {
        ...state,
        loading: false,
        errors: null,
        backgroundActors: sortedActors,
        backgroundActorsSignedReleasePercentage: action.payload.data.backgroundActorsSignedReleasePercentage,
        readOnly: action.payload.data.readOnly
      };
    }

    case FETCH_BACKGROUND_ACTOR_OVERVIEW + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.errors
      };
    }

    case UPDATE_BACKGROUND_ACTOR_SIGNED_RELEASE + "_PENDING": {
      const { backgroundActor } = action.meta;
      const backgroundActors = Array.from(state.backgroundActors);
      const backgroundActorIndex = backgroundActors.findIndex(a => a.id == backgroundActor.id);
      if (backgroundActorIndex > -1) {
        backgroundActors[backgroundActorIndex].updating = true;
      }

      return {
        ...state,
        backgroundActors,
        errors: null
      };
    }

    case UPDATE_BACKGROUND_ACTOR_SIGNED_RELEASE + "_FULFILLED": {
      const { backgroundActor, signed } = action.meta;

      const backgroundActors = Array.from(state.backgroundActors);
      const backgroundActorIndex = backgroundActors.findIndex(a => a.id == backgroundActor.id);
      if (backgroundActorIndex > -1) {
        backgroundActors[backgroundActorIndex].signedRelease = signed;
        backgroundActors[backgroundActorIndex].updating = false;
      }

      return {
        ...state,
        backgroundActors,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case UPDATE_BACKGROUND_ACTOR_SIGNED_RELEASE + "_REJECTED": {
      const { backgroundActor } = action.meta;
      const backgroundActors = Array.from(state.backgroundActors);
      const backgroundActorIndex = backgroundActors.findIndex(a => a.id == backgroundActor.id);
      if (backgroundActorIndex > -1) {
        backgroundActors[backgroundActorIndex].updating = false;
      }
      return {
        ...state,
        backgroundActors,
        errors: action.payload.response.errors
      };
    }

    default:
      return state;
  }
};

const reorderActors = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  result[sourceIndex].number = destinationIndex + 1;

  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      result[i].number++;
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      result[i].number--;
    }
  }

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

export function sortByNumber(list) {
  list.sort((a, b) => {
    return a.number - b.number;
  });
}

export default reducer;
