import React from "react";
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Errors from "../../../shared/errors";
import {
    OnboardingStep1ClassName,
    OnboardingStep2ClassName
} from "./ducks/reducer";
import OverviewBackgroundActor from './overview-item';
import { Prompt } from "react-router-dom";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";

function ActorOverview(props) {
    const {
        backgroundActors,
        updateBackgroundActorSignedRelease,
        filmId,
        errors,
        warnings,
        onboardingSteps,
        readOnly,
        updateActorOrder,
        saveActorOrder,
        pristine
    } = props;


    const backgroundActorsWithCharacters = backgroundActors.filter(a => a.backgroundCharacters != null && a.backgroundCharacters.length > 0);
    const totalBackgroundActorsWithCharacters = backgroundActorsWithCharacters.length;
    const backgroundActorsWithSignedRelease = backgroundActorsWithCharacters.filter(c => c.signedRelease);
    const backgroundActorsWithSignedReleaseCount = backgroundActorsWithSignedRelease.length;
    const backgroundActorsSignedReleasePercentage = totalBackgroundActorsWithCharacters == 0
        ? 0
        : (backgroundActorsWithSignedReleaseCount / totalBackgroundActorsWithCharacters) * 100;

    return (
        <div>
            {errors && <Errors errors={errors} />}
            {warnings && <Errors errors={warnings} isWarning={true} />}
            <div className="columns">
                <div
                    className={`${OnboardingStep1ClassName} circular-progress column is-one-quarter`}>
                    <FilmalityCircularProgress
                        percentage={backgroundActorsSignedReleasePercentage}
                        title="Signed Release"
                    />
                </div>
            </div>

            <div>
                <table className={`table`}>
                    <thead>
                        <tr>
                            <th>Actor</th>
                            <th>Characters</th>
                            <th>Signed Release</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(backgroundActors || []).map((actor: any, index: number) => (
                            <OverviewBackgroundActor key={actor.id} actor={actor} index={index} readOnly={readOnly} filmId={filmId} updateBackgroundActorSignedRelease={updateBackgroundActorSignedRelease} />
                        ))}


                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default ActorOverview;
